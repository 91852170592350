import React from "react"
import IndexButtons from "../shared/buttons/IndexButtons";

export default class Row extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false,
    };

    this.requeue = this.requeue.bind(this)
  }

  requeue() {
    if (confirm("Are you sure you want to requeue this job?")) {
      let that = this;
      const formData = new FormData();
      let data = JSON.stringify({
        id: this.props.job_tracker.id
      })
      formData.append('job_tracker', data)

      fetch(this.props.job_tracker.url_requeue, {
        method: 'POST',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors
          })
        } else {
          that.setState({
            hidden: true
          })

          window.location.reload();
        }
      })
    }
  }

  render () {
    let { hidden } = this.state;
    let { job_tracker } = this.props;

    return (
      hidden ? (
        null
      ) : (
        <tr style={{ color:`${job_tracker.failed_job ? "red" : (job_tracker.incomplete_job ? "orange" : "black")}` }}>
          <th style={{ minWidth:"50px" }}>
            {
              job_tracker.incomplete_job || job_tracker.failed_job ? (
                <div className="hoverable" style={{ padding:"5px",margin:"5px" }} onClick={this.requeue}>
                  <i class="fas fa-sync-alt"></i>
                </div>
              ) : (
                null
              )
            }
          </th>
          <td scope="row">
            <span>{job_tracker.name}</span>
            <div>[{job_tracker.id}]</div>
          </td>
          <td style={{ minWidth:"110px" }}>{job_tracker.queued_at}</td>
          <td style={{ minWidth:"110px" }}>{job_tracker.scheduled_at}</td>
          <td style={{ minWidth:"110px" }}>{job_tracker.failed_at || '-'}</td>
          <td style={{ minWidth:"320px",maxWidth:"320px",wordWrap:"break-word" }}>{job_tracker.error_message || '-'}</td>
          <td style={{ minWidth:"500px",maxWidth:"500px",wordWrap:"break-word" }}>{job_tracker.args}</td>
          <td>
            <IndexButtons
              id={job_tracker.id}
              url_show={null}
              url_edit={null}
              url_delete={null}
              hide_message={""}
            />
          </td>
        </tr>
      )
    )
  }
}
