import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import ProductRow from "./ProductRow";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloading: false,
      errors: "",
      loading: false,
      onDesktop: true,
      showSuccess: false,
      upc: "",
    };

    this.addProduct = this.addProduct.bind(this)
    this.changeUpc = this.changeUpc.bind(this)
    this.downloadCsv = this.downloadCsv.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  addProduct() {
    this.setState({
      loading: true,
      showSuccess: false,
      errors: "",
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      upc: this.state.upc
    })
    formData.append('marianos_product', data)

    fetch(this.props.url_create, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false,
        })
      } else {
        that.setState({
          upc: "",
          loading: false,
          showSuccess: true,
        })
      }
    })
  }

  changeUpc(event) {
    this.setState({
      upc: event.target.value
    });
  }

  downloadCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `marianos_products_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  render () {
    let { products, showdrop_logo } = this.props;
    let { downloading, errors, loading, onDesktop, showSuccess, upc } = this.state;
    let pageName = 'marianos_products';

    let showMessage = () => {
      if (errors) {
        return (
          <div style={{ padding:"10px 10px 0",fontSize:"10px",fontStyle:"italic",textAlign:"center",color:"red" }}>
            {errors}
          </div>
        )
      } else if (showSuccess) {
        return (
          <div style={{ padding:"10px 10px 0",fontSize:"10px",fontStyle:"italic",textAlign:"center",color:"green" }}>
            Product will be added soon, come back later.
          </div>
        )
      } else {
        return (
          <div style={{ padding:"10px 10px 0",height:"25px" }}></div>
        )
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Marianos Products'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="col pos-a">
                <span className="bold">{products.length}</span> products
              </div>

              <div className="col">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Marianos Products")}
                >
                  <button type="button" className="btn btn-sm btn-success mb-5px fs-17 w35 floatr" onClick={this.downloadCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-download"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <div className="d-flex" style={{ padding:"20px 0" }}>
              <div className="f1" style={{ maxWidth:"75%",margin:"auto" }}>
                <div className="d-flex" style={{ padding:"0 10px" }}>
                  <input className="form-control br-r-4 f1" placeholder="Enter UPC.." onChange={this.changeUpc} value={upc} disabled={loading ? 'disabled' : ''} />

                  <button className="btn btn-primary btn-sm bold f1" style={{ maxWidth:"150px",marginLeft:"10px" }} type="submit" onClick={loading ? null : this.addProduct} disabled={loading ? 'disabled' : ''}>
                    {
                      loading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        "Add Product"
                      )
                    }
                  </button>
                </div>

                {showMessage()}
              </div>
            </div>

            <table className="table table-long table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Track Sales?</th>
                  <th scope="col">ID</th>
                  <th scope="col">UPC</th>
                  <th scope="col">Brand Name</th>
                  <th scope="col">Product Name</th>
                </tr>
              </thead>
              <tbody>
                {products.map(product => {
                  return (
                    <ProductRow product={product} />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
