import React from "react"
import IndexButtons from "../shared/buttons/IndexButtons";

export default class PreCampaignRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      aisleLocation: this.props.preCampaign.aisle_location,
      changed: false,
      errors: "",
      loading: false,
    };

    this.changeAisleLocation = this.changeAisleLocation.bind(this)
    this.updatePreCampaign = this.updatePreCampaign.bind(this)
  }

  changeAisleLocation(event) {
    this.setState({
      changed: true,
      aisleLocation: event.target.value
    })
  }

  updatePreCampaign(preCampaign) {
    this.setState({
      errors: "",
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.preCampaign.id,
      aisle_location: this.state.aisleLocation,
    })
    formData.append('pre_campaign', data)

    fetch(this.props.preCampaign.url_update, {
      method: 'PUT',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors,
            loading: false,
          })
        } else {
          that.setState({
            changed: false,
            loading: false,
          })
        }
      }
    )
  }

  render () {
    let { aisleLocation, changed, errors, loading } = this.state;
    let { preCampaign } = this.props;

    return (
      <tr>
        <th style={{ width:"50px" }} scope="row">
          {
            loading ? (
              <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className="btn btn-sm btn-secondary mlr-5" onClick={null} disabled="disabled">
                <i className="fas fa-circle-notch fa-spin"></i>
              </button>
            ) : (
              <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className={`btn btn-sm ${changed ? "btn-success" : "btn-secondary"} mlr-5`} onClick={() => this.updatePreCampaign(preCampaign)} disabled={changed ? "" : "disabled"}>
                <i className="fas fa-save"></i>
              </button>
            )
          }
        </th>
        <th style={{ width:"50px" }}>{preCampaign.id}</th>
        <td>
          {
            preCampaign.campaign_live ? (
              <i className="fas fa-check-circle color-primary" style={{ marginRight:"4px" }}></i>
            ) : (
              <></>
            )
          }

          {preCampaign.machine.name} [{preCampaign.machine.id}]
        </td>
        <td style={{ wordWrap:"break-word" }}>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="-" onChange={this.changeAisleLocation} value={aisleLocation} />
          </div>
        </td>
      </tr>
    )
  }
}
