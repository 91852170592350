import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Row from "./Row";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true,
      jobTrackerList: this.props.job_trackers,
      selectedByAll: true,
      selectedByFailed: false,
      selectedByIncomplete: false,
      selectedByQueued: false,
    };

    this.getByAll = this.getByAll.bind(this)
    this.getByFailed = this.getByFailed.bind(this)
    this.getByIncomplete = this.getByIncomplete.bind(this)
    this.getByQueued = this.getByQueued.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  getByAll() {
    this.setState({
      selectedByAll: true,
      selectedByFailed: false,
      selectedByIncomplete: false,
      selectedByQueued: false,
      jobTrackerList: this.props.job_trackers
    })
  }

  getByFailed() {
    let jobTrackerList = this.props.job_trackers.filter(jt => jt.failed_job == true)

    this.setState({
      selectedByAll: false,
      selectedByFailed: true,
      selectedByIncomplete: false,
      selectedByQueued: false,
      jobTrackerList: jobTrackerList
    })
  }

  getByIncomplete() {
    let jobTrackerList = this.props.job_trackers.filter(jt => jt.incomplete_job == true)

    this.setState({
      selectedByAll: false,
      selectedByFailed: false,
      selectedByIncomplete: true,
      selectedByQueued: false,
      jobTrackerList: jobTrackerList
    })
  }

  getByQueued() {
    let jobTrackerList = this.props.job_trackers.filter(jt => jt.incomplete_job != true && jt.failed_job != true)

    this.setState({
      selectedByAll: false,
      selectedByFailed: false,
      selectedByIncomplete: false,
      selectedByQueued: true,
      jobTrackerList: jobTrackerList
    })
  }

  render () {
    let { job_trackers, showdrop_logo } = this.props;
    let { jobTrackerList, onDesktop, selectedByAll, selectedByFailed, selectedByIncomplete, selectedByQueued } = this.state;
    let pageName = 'job_trackers';

    let activelyQueuedTrackers = job_trackers.filter(jt => jt.incomplete_job != true && jt.failed_job != true);
    let incompleteTrackers = job_trackers.filter(jt => jt.incomplete_job == true);
    let failedTrackers = job_trackers.filter(jt => jt.failed_job == true);
    let recentFailedTrackers = failedTrackers.filter(jt => jt.recent_job );
    let recentIncompleteTrackers = incompleteTrackers.filter(jt => jt.recent_job );

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Job Trackers'}
              url={null}
            />

            <div className="row pb-10 pos-r" style={{ minHeight:"40px" }}>
              <div className="col">
                <div>
                  <span className="bold">{activelyQueuedTrackers.length}</span> actively queued
                </div>
                <div>
                  <span style={{ color:"orange" }}>{incompleteTrackers.length} <span style={{ fontWeight:"bold" }}>({recentIncompleteTrackers.length})</span></span> incomplete
                </div>
                <div>
                  <span style={{ color:"red" }}>{failedTrackers.length} <span style={{ fontWeight:"bold" }}>({recentFailedTrackers.length})</span></span> failed
                </div>
                <div style={{ fontSize:"11px" }}>(count in parenthesis is within the last day)</div>
              </div>
            </div>

            <div className="row bg-extra-gray" style={{ margin:"0" }}>
              <div className="col">
                <div style={{ display:"flex",width:"80%",margin:"auto" }}>
                  <div className="hoverable" onClick={this.getByAll} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedByAll ? "#20cb9a" : "white"}`,color:`${selectedByAll ? "white" : "black"}` }}>
                    All
                  </div>

                  <div className="hoverable" onClick={this.getByQueued} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedByQueued ? "#20cb9a" : "white"}`,color:`${selectedByQueued ? "white" : "black"}` }}>
                    Queued
                  </div>

                  <div className="hoverable" onClick={this.getByIncomplete} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedByIncomplete ? "#20cb9a" : "white"}`,color:`${selectedByIncomplete ? "white" : "black"}` }}>
                    Incomplete
                  </div>

                  <div className="hoverable" onClick={this.getByFailed} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedByFailed ? "#20cb9a" : "white"}`,color:`${selectedByFailed ? "white" : "black"}` }}>
                    Failed
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ margin:"0" }}>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col" style={{ minWidth:"50px" }}></th>
                    <th scope="col">Name</th>
                    <th scope="col" style={{ minWidth:"110px" }}>Queued At</th>
                    <th scope="col" style={{ minWidth:"110px" }}>Scheduled At</th>
                    <th scope="col" style={{ minWidth:"110px" }}>Failed At</th>
                    <th scope="col" style={{ minWidth:"320px",maxWidth:"320px" }}>Error Message</th>
                    <th scope="col" style={{ minWidth:"500px",maxWidth:"500px" }}>Args</th>
                    <th scope="col" className="w200"></th>
                  </tr>
                </thead>
                <tbody>
                  {jobTrackerList.map(job_tracker => {
                    return (
                      <Row job_tracker={job_tracker} />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
